import Vue from "vue";
import DatePicker from "vue2-datepicker";
import moment from "moment"
import Loading from "@/components/widgets/Loading.vue";
import SortedTablePlugin from "vue-sorted-table";
import UploadSlip from "@/components/widgets/widget-upload-slip";
import PreviewSlip from "@/components/widgets/widget-invoice-slips";
import MenuList from './widget-menu'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});
export default {
  components: {
    Loading,
    DatePicker,
    UploadSlip,
    PreviewSlip,
    MenuList,
    Paginate
  },
  data: function () {
    return {
      role: "",
      branch: "",
      keyword_document_no: "",
      keyword_status: "",
      keyword_start: null,
      keyword_end: null,
      keyword_branch: null,
      busy: false,
      apiStatus: "ไม่พบข้อมูล",
      totalItem: 0,
      loading: false,
      state: false,
      getPaid: {
        Id: null,
        state: false,
      },
      preview: {
        uuid: "",
        data: [],
        state: false,
        status: 0,
        role: "",
        branch: "",
      },
      status: null,
      doc: {
        start: null,
        end: null,
      },
      currentData: {},
      sortBy: "name",
      currentPage: 1,
      perPage: 8,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: "quotation_no",
          label: "เลขที่ใบเสนอราคา",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "quotation_date",
          label: "ลงวันที่",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "customer_detail.name",
          label: "ชื่อลูกค้า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        // {
        //   key: "customer_detail.company_name",
        //   label: "บริษัท",
        //   thClass: "font-weight-bold text-xs-1/2",
        //   tdClass: "text-gray-400 td-top text-xs-1/2",
        //   sortable: true,
        // },
        {
          key: "grandtotal",
          label: "มูลค่า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "creator_detail.name",
          label: "ผู้จัดทำ",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          sortable: true,
        },
        {
          key: "action",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
          class: "text-right",
        },
      ],
      items: [],
      statusOption: [
        { value: 0, text: "กำลังจัดทำ" }, //#d9d9d9
        { value: 1, text: "รอตรวจสอบ" }, //#ffff00
        { value: 2, text: "ยืนยันราคาแล้ว" }, //#00b0f0
        { value: 3, text: "Revise ราคา" }, //ff9900
        { value: 4, text: "ยกเลิก" }, //#ff0000
        { value: 5, text: "ตรวจสอบไม่ผ่าน" }, //931d8b
        { value: 6, text: "ได้งาน" }, //00b050
        { value: 7, text: "เทียบราคา" }, //#808080
        { value: 8, text: "คู่เทียบ" }, //#833c0c
        { value: 9, text: "ไม่ได้งาน" }, //#ff0000
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    async getRole() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      this.role = res.data.role;
      this.branch = res.data.branch_id;
    },
    onConvertToBill(uuid){
      this.$router.push({ path: 'billing/create', query: { q: uuid } })
    },
    handleGetPaidEvent(e) {
      this.getPaid.state = false;
      if (e.status) {
        this.onInitData(this.currentPage);
      }
    },
    handleShowSlipEvent(e) {
      this.preview.state = false;
      if (e.status) {
        this.onInitData(this.currentPage);
      }
    },
    onShowSlip(data, uuid, status, role, branch) {
      this.preview.uuid = uuid;
      this.preview.data = data;
      this.preview.status = status;
      this.preview.role = role;
      this.preview.branch = branch;
      this.preview.state = !this.preview.state;
    },
    onGetPaid(id) {
      this.getPaid.Id = id;
      this.getPaid.state = !this.getPaid.state;
    },
    oncreate() {
      this.$router.push("/quotation/create");
    },
    onCopyQuotation(item) {
      this.$swal({
        title: "แจ้งเตือน",
        text: "คุณต้องการจะที่คัดลอกใบเสนอราคานี้ใช่หรือไม่",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.post(
              `${Vue.prototype.$host}/api/v1/document/copyQuotation/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("คัดลอกสำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onApproveQuotation(item) {
      this.$swal({
        title: "แจ้งเตือน",
        text: "คุณต้องการยืนยันใบเสนอราคานี้ใช่หรือไม่",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/approve/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("Approve สำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onRejectQuotation(item) {
      this.$swal({
        title: "แจ้งเตือน",
        text: "คุณต้องการปฏิเสธใบเสนอราคานี้ใช่หรือไม่",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/reject/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("Reject สำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onReviseQuotation(item) {
      this.$router.push("/quotation/revise/" + item.uuid);
    },
    onModifyQuotation(item) {
      this.$router.push("/quotation/edit/" + item.uuid);
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage;
      this.busy = true;
      this.apiStatus = "กำลังค้นหาข้อมูล..";
      this.items = [];
      try {
        const page = currentPage ? currentPage : 1;
        const start = this.keyword_start ? moment(this.keyword_start).format('YYYY-MM-DD') : ''
        const end = this.keyword_end ? moment(this.keyword_end).format('YYYY-MM-DD') : ''
        const branch = this.keyword_branch ? this.keyword_branch : ''
        const result = await Vue.prototype.$axios.get(
          `${Vue.prototype.$host}/api/v1/document/quotation?page=${page}&keyword_document_no=${this.keyword_document_no}&keyword_status=${this.keyword_status}&keyword_start=${start}&keyword_end=${end}&keyword_branch=${branch}`
        );
        this.busy = false;

        this.apiStatus = "ไม่พบข้อมูล";
        this.currentData = result.data;
        this.items = result.data.data;
        this.totalItem = result.data.total;
      } catch (error) {
        this.apiStatus = "ไม่พบข้อมูล";
        this.onExceptionHandler(error.response.data.message);
        this.busy = false;
        return [];
      }
    },
    onSelectQuotationType(uuid){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'โปรดเลือกรูปแบบใบเสนอราคาที่ต้องการ',
        showCancelButton: true,
        confirmButtonText: "แบบปกติ",
        cancelButtonText: "แบบใหม่",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#235532",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          this.generateQRcode(uuid)
        }
        else if (result.dismiss === 'cancel') {
          this.generateQRcode2(uuid)
        }
        else{
          this.loading = false
        }
      });
    },
    generateQRcode(uuid) {
      this.loading = true;
      setTimeout(async () => {
        this.loading = true

        const result = await this.$axios.get(
          this.$host + "/api/v1/downloadQuotationPDF/" + uuid,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([result.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.open(objectUrl)
          // const link = document.createElement('a')
          // link.href = objectUrl2
          // link.setAttribute('download', 'ใบเสนอราคา ' +  + '.pdf')
          // document.body.appendChild(link)
          // link.click()

          this.loading = false;
        } else {
          window.open(
            objectUrl,
            "targetWindow",
            `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=SomeSize,
           height=SomeSize`
          );
          this.loading = false;
        }
        // URL.revokeObjectURL(objectUrl);
      }, 1000);
    },
    generateQRcode2(uuid) {
      this.loading = true;
      setTimeout(async () => {
        this.loading = true

        const result = await this.$axios.get(
          this.$host + "/api/v1/downloadQuotationPDF2/" + uuid,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([result.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.open(objectUrl)
          // const link = document.createElement('a')
          // link.href = objectUrl2
          // link.setAttribute('download', 'ใบเสนอราคา ' +  + '.pdf')
          // document.body.appendChild(link)
          // link.click()

          this.loading = false;
        } else {
          window.open(
            objectUrl,
            "targetWindow",
            `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=SomeSize,
           height=SomeSize`
          );
          this.loading = false;
        }
        // URL.revokeObjectURL(objectUrl);
      }, 1000);
    },
    onConfirmInvoice(id) {
      this.$swal({
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะยืนยันราคาตามใบเสนอราคานี้หรือไม่?</span>' +
          "</div>" +
          "</div>",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (confirm) => {
        if (confirm.value) {
          this.loading = true;
          this.realConfirm(id);
        }
      });
    },
    async realConfirm(id) {
      try {
        await this.$axios.post(this.$host + "/api/v1/confirmprice/" + id);

        this.loading = false;
        this.$swal({
          width: "29rem",
          padding: "1.825em",
          html:
            '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
            '<div class="d-flex flex-column">' +
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
            '<span class="text-base text-left text-gray-500">ดำเนินการเรียบร้อยแล้ว</span>' +
            "</div>" +
            "</div>",
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: "swal2-custom-rounded",
            closeButton: "text-3xl swal2-custom-close",
            actions: "justify-content-end",
            contant: "d-flex flex-row justify-content-around px-2",
            title: "d-none",
            confirmButton:
              "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton:
              "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          },
          cancelButtonColor: "#dc4a38",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ตกลง",
          showCancelButton: false,
          showCloseButton: true,
        }).then(async (confirm) => {
          if (confirm.value) {
            this.onInitData(this.currentPage);
          }
        });
      } catch (error) {
        this.loading = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async Export() {
      try {
        this.loading = true
        await this.$axios.post(`${Vue.prototype.$host}/api/v1/exportQuotation`, {}, {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'quotation.xlsx')
          document.body.appendChild(link)
          link.click()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onExportOneQuotation(item) {
      try {
        this.loading = true
        await this.$axios.post(`${Vue.prototype.$host}/api/v1/exportQuotation/${item.uuid}`, {}, {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.quotation_no + '.xlsx')
          document.body.appendChild(link)
          link.click()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onWin(item){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'คุณต้องการที่จะตั้งสถานะใบเสนอราคาเป็น "ได้งาน" ใช่หรือไม่',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/win/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("ตั้งสถานะใบเสนอราคาสำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onCompare(item){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'คุณต้องการที่จะตั้งสถานะใบเสนอราคาเป็น "เทียบราคา" ใช่หรือไม่',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/compare/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("ตั้งสถานะใบเสนอราคาสำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onClaque(item){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'คุณต้องการที่จะตั้งสถานะใบเสนอราคาเป็น "คู่เทียบ" ใช่หรือไม่',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/claque/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("ตั้งสถานะใบเสนอราคาสำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onLose(item){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'คุณต้องการที่จะตั้งสถานะใบเสนอราคาเป็น "ไม่ได้งาน" ใช่หรือไม่',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/lose/${item.uuid}`
            );

            this.loading = false;
            if (result.status === 200) {
              alert("ตั้งสถานะใบเสนอราคาสำเร็จ");

              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    onCancel(item){
      this.$swal({
        title: "แจ้งเตือน",
        text: 'คุณต้องการที่จะตั้งสถานะใบเสนอราคาเป็น "ยกเลิก" หรือไม่',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            const result = await Vue.prototype.$axios.patch(
              `${Vue.prototype.$host}/api/v1/document/quotation/cancel/${item.uuid}`
            );
  
            this.loading = false;
            if (result.status === 200) {
              alert("ตั้งสถานะใบเสนอราคาสำเร็จ");
  
              this.onInitData(this.currentPage);
            }
          } catch (error) {
            this.loading = false;
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.onExceptionHandler(message);
          }
        }
      });
    },
    async getBranches() {
      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.branches = result.data
    },
  },
  mounted() {
    this.getBranches()
    this.onInitData(this.currentPage);
    this.getRole();
  },
};
